//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
    data() { 
        return {
            random: 0
        }
    },
    mounted(){
        var min = Math.ceil(0);
        var max = Math.floor(11);
        this.random = Math.floor(Math.random() * (max - min + 1)) + min;
    },
    computed: {
        ...mapState({
            cams: ({ camStore: {cams} }) => cams
        }),
    }
}
